<template>
  <div class="upload-files">
    <b-list-group flush>
      <b-list-group-item href="#" v-for="(file, $index) in files" :key="$index" :active="file.id === active">
        <b-row>
          <b-col cols="2">
            <img v-if="file.thumb" :src="file.thumb" class="img img-fluid" />
            <img v-else :src="require('@/assets/images/dcm-icon.png')" class="img img-fluid" />
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <strong class="color-white">{{ file.name }}</strong>
              </b-col>
              <b-col cols="3" class="text-right line-height-1">
                <small><i>
                  <span :class="{ 'text-danger font-weight-900': file.error,  'text-success font-weight-900': file.success }">
                    Status: {{ file.success ? "Uploaded" : (file.error ? file.error.charAt(0).toUpperCase() + file.error.substr(1) : (parseInt(file.progress) === 0 ? "Ready to Upload" : (parseInt(file.progress) === 100 ? "Processing" : "Uploading"))) }}
                  </span><br />
                  <span v-if="parseInt(file.progress) === 0">Uploading in {{ file.seconds }}s</span>
                  <span v-else>Progress: {{ parseInt(file.progress) }}%</span>
                  <span v-if="file.speed > 0"><br />Speed: {{ file.speed | formatSize }}</span>
                </i></small>
              </b-col>
            </b-row>
            <b-row class="bottom">
              <b-col class="d-flex pb-1">
                <small class="text-muted mt-auto">{{ file.size | formatSize }}</small>
              </b-col>
              <b-col class="text-right pr-0">
                <b-button variant="link" :disabled="file.success" @click.prevent="deleteFile(file)">
                  <font-awesome-icon icon="trash"></font-awesome-icon>
                </b-button>
                <b-button variant="link" :disabled="parseInt(file.progress) > 0" @click.prevent="file.active || file.success || file.error === 'compression' ? false : onEditFileShow(file)">
                  <font-awesome-icon icon="pen"></font-awesome-icon>
                </b-button>
                <b-button variant="link" :disabled="parseInt(file.progress) > 0" @click.prevent="file.success || file.error === 'compressing' ? false : uploadNow(file)">
                  <font-awesome-icon icon="upload"></font-awesome-icon>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--<b-row>
          <b-col>
            <b-badge pill class="indicator" :class="getIndicator(study.conclusion)">
              <font-awesome-icon :icon="getIcon(study.conclusion)"></font-awesome-icon> {{ getConclusion(study.conclusion) }}
            </b-badge>
          </b-col>
          <b-col class="text-right">
            <small><em>{{ formatDate(study.created_at.date) }}</em></small>
          </b-col>
        </b-row>-->
      </b-list-group-item>
    </b-list-group>
    <div class="m-2 p-5 text-center upload-files-area">
      <div class="my-2">
        <h5 class="mb-0">Drag &amp; Drop Image(s)</h5>
        <small>Daily Limit: 1000 images, Images Left: {{ 1000 - (user ? user.imagesToday : 1000) }} images</small><br />
        <small class="text-muted">Only "{{ accept.split('image/').join('').split(',').join(', ') }}" images are accepted.</small><br />
        <file-upload
          class="btn btn-primary btn-sm mt-4 px-5 rounded-pill"
          ref="upload"
          v-model="files"
          :post-action="apiRoute + '/api/v1/staff/pneumonia'"
          :drop="true"
          :multiple="true"
          :driectory="true"
          :drop-directory="true"
          :add-index="true"
          :headers="headers"
          :data="data"
          :maximum="1000 - (user ? user.imagesToday : 1000) > 100 ? 100 : 1000 - (user ? user.imagesToday : 1000)"
          @input-filter="inputFilter"
          @input-file="inputFile"
        >
          Add
        </file-upload>
      </div>
    </div>

    <div :class="{'modal-backdrop': true, 'fade': true, show: editFile.show}"></div>
    <div
      :class="{modal: true, fade: true, show: editFile.show}"
      id="modal-edit-file"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit file</h5>
            <button type="button" class="close" @click.prevent="closeEdit()">
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onEditorFile">
            <div class="modal-body">
              <div class="form-group">
                <label for="name">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="name"
                  placeholder="Please enter a file name"
                  v-model="editFile.name"
                />
              </div>
              <div
                class="form-group"
                v-if="editFile.show && editFile.blob && editFile.type && editFile.type.substr(0, 6) === 'image/'"
              >
                <label>Image:</label>
                <div class="edit-image">
                  <img :src="editFile.blob" ref="editImage" />
                </div>

                <div class="edit-image-tool">
                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.rotate(-90)"
                      title="cropper.rotate(-90)"
                    >
                      <font-awesome-icon icon="undo" aria-hidden="true"></font-awesome-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.rotate(90)"
                      title="cropper.rotate(90)"
                    >
                      <font-awesome-icon icon="redo" aria-hidden="true"></font-awesome-icon>
                    </button>
                  </div>
                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.crop()"
                      title="cropper.crop()"
                    >
                      <font-awesome-icon icon="check" aria-hidden="true"></font-awesome-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.clear()"
                      title="cropper.clear()"
                    >
                      <font-awesome-icon icon="times" aria-hidden="true"></font-awesome-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="closeEdit()"
              >Close</button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.width-80 {
  width: 80%;
  border: 1px solid #14304C;
}
.upload-files-area {
  border-radius: 1.25rem;
}
.upload-files-area:hover {
  background-color: #14304C33;
}
.line-height-1 {
  line-height: 1.2;
}
.modal-backdrop.fade {
  visibility: hidden;
}
.modal-backdrop.fade.show {
  visibility: visible;
}
.fade.show {
  display: block;
  z-index: 1072;
}
.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
</style>

<script>
import { apiRoute } from './../../../helpers'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import ImageCompressor from '@xkeshi/image-compressor'
import md5 from 'js-md5'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      active: null,
      files: [],
      accept: 'image/png,image/jpeg,image/tiff,image/bmp,image/dcm',
      extensions: 'jpeg|jpe|jpg|png|tiff|bmp|dcm',
      uploadAuto: false,
      editFile: {
        show: false,
        name: ''
      },
      uploadIn: 10,
      noOfFiles: 0,
      headers: {
        Authorization: 'Bearer ',
        project: '67c58b55eb08910013ecb5f7'
      },
      data: {
        _hash: null,
        user: this.user ? this.user.email : ''
      }
    }
  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        this.data._hash = md5(ip + Date.now().toString())
      })
  },
  mounted () {
    if (this.user) {
      this.headers.Authorization = 'Bearer ' + this.user.token
    } else {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    user () {
      if (this.auth && this.auth.status && this.auth.status.loggedIn) {
        return this.auth.user
      } else {
        return null
      }
    },
    apiRoute () {
      return apiRoute()
    }
  },
  methods: {
    ...mapActions('alert', ['success']),
    ...mapActions('auth', ['increaseImagesToday']),
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }

        if (
          newFile.file &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512
          })
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type
              })
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || 'compress'
              })
            })
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }

        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        newFile.seconds = this.uploadIn + (this.noOfFiles++ * 30)
        newFile.countdown = setInterval(() => {
          if (newFile.seconds > 1) {
            newFile.seconds -= 1
            this.$refs.upload.update(newFile, { seconds: newFile.seconds })
          } else {
            clearInterval(newFile.countdown)
            newFile.countdown = null
            this.$refs.upload.update(newFile, { countdown: null })
            this.$refs.upload.update(newFile, { active: true })
          }
        }, 1000)
      }

      if (!newFile && oldFile) {
        console.log('Old file: ', oldFile.name)
      }

      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: 'size' })
          }
        }

        if (newFile.progress !== oldFile.progress) {
          // progress
        }

        if (newFile.error && !oldFile.error) {
          // error
        }

        if (newFile.success && !oldFile.success) {
          this.success('File successfully processed, removing from upload list.')
          this.increaseImagesToday()
          setTimeout(this.deleteFile(newFile), 500)
          this.$emit('refresh-studies', true)
        }
      }

      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    onAddFolder () {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support')
        return
      }

      const input = this.$refs.upload.$el.querySelector('input')
      input.directory = true
      input.webkitdirectory = true
      this.directory = true

      input.onclick = null
      input.click()
      input.onclick = () => {
        this.directory = false
        input.directory = false
        input.webkitdirectory = false
      }
    },
    deleteFile (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.$refs.upload.remove(file)
    },
    uploadNow (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.$refs.upload.update(file, { active: true })
    },
    onEditFileShow (file) {
      if (file.countdown) {
        clearInterval(file.countdown)
        file.countdown = null
        this.$refs.upload.update(file, { countdown: null })
        file.seconds = 0
        this.$refs.upload.update(file, { seconds: 0 })
      }
      this.editFile = { ...file, show: true }
      this.$refs.upload.update(file, { error: 'edit' })
    },
    onEditorFile () {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }

      const data = {
        name: this.editFile.name
      }

      if (this.editFile.cropper) {
        const binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(',')[1]
        )
        const arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        data.file = new File([arr], data.name, { type: this.editFile.type })
        data.size = data.file.size
      }
      this.$refs.upload.update(this.editFile.id, data)
      this.editFile.error = ''
      this.editFile.show = false

      this.editFile.seconds = this.uploadIn
      this.editFile.countdown = setInterval(() => {
        if (this.editFile.seconds > 1) {
          this.editFile.seconds -= 1
          this.$refs.upload.update(this.editFile.id, { seconds: this.editFile.seconds })
        } else {
          clearInterval(this.editFile.countdown)
          this.editFile.countdown = null
          this.$refs.upload.update(this.editFile.id, { countdown: null })
          this.$refs.upload.update(this.editFile.id, { active: true })
        }
      }, 1000)
      this.$refs.upload.update(this.editFile.id, { countdown: this.editFile.countdown })
    },
    closeEdit () {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support')
        this.editFile.show = false
        return
      }

      this.editFile.error = ''
      this.editFile.show = false

      this.editFile.seconds = this.uploadIn
      this.editFile.countdown = setInterval(() => {
        if (this.editFile.seconds > 1) {
          this.editFile.seconds -= 1
          this.$refs.upload.update(this.editFile.id, { seconds: this.editFile.seconds })
        } else {
          clearInterval(this.editFile.countdown)
          this.editFile.countdown = null
          this.$refs.upload.update(this.editFile.id, { countdown: null })
          this.$refs.upload.update(this.editFile.id, { active: true })
        }
      }, 1000)
      this.$refs.upload.update(this.editFile.id, { countdown: this.editFile.countdown })
    }
  },
  watch: {
    'editFile.show' (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ''
        })
      }

      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          const cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false
          })
          this.editFile = {
            ...this.editFile,
            cropper
          }
        })
      }
    }
  }
}
</script>
