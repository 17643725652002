<template>
  <div id="MainView">
    <b-navbar toggleable="lg" type="dark" class="px-4 py-3">
      <b-nav-form class="navy-fields">
        <b-input-group class="rounded-pill">
          <template #prepend>
            <b-input-group-text class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control">
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control">
              <b-badge variant="light" class="hand-pointer px-2" pill @click.prevent="loadMore()" v-if="loadedTotal < total">
                {{ filteredList('range1').length + filteredList('range2').length + filteredList('range3').length + filteredList('range4').length + filteredList('range5').length | formatInt }}
                of {{ total | formatInt }}
                <font-awesome-icon :icon="loadState ? 'download' : 'sync-alt'" size="sm" class="ml-1" />
              </b-badge>
              <b-badge variant="light" class="px-2" pill v-else>
                {{ filteredList('range1').length + filteredList('range2').length + filteredList('range3').length + filteredList('range4').length + filteredList('range5').length | formatInt }}
                of {{ total | formatInt }}
              </b-badge>
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search for patient in loaded studies ..."
            style="width: 25rem;"
            debounce="500"
          ></b-form-input>
        </b-input-group>
        <b-dropdown ref="calendar-dropdown" class="mx-2 btn btn-sm btn-primary rounded-pill calendar-dropdown">
          <template #button-content>
            <font-awesome-icon icon="calendar-alt" size="sm" class="mr-2" />
            {{ dateFrom | formatDateShort }} - {{ dateTo | formatDateShort }}
          </template>
          <b-row class="calendar-container">
            <b-col>
              <b-calendar
                v-model="dateFrom"
                @selected="onDateFromSelected"
                :date-info-fn="dateInfoFn"
              ></b-calendar>
            </b-col>
            <b-col>
              <b-calendar
                v-model="dateTo"
                @selected="onDateToSelected"
                :min="dateFrom"
                :date-info-fn="dateInfoFn"
              ></b-calendar>
            </b-col>
          </b-row>
        </b-dropdown>
        <div v-if="!currentImages">
          <a href="#" @click.prevent="currentImages = !currentImages">
            <font-awesome-icon icon="check-square" class="color-yellow mr-1 font-size-14" v-if="currentImages"></font-awesome-icon>
            <font-awesome-icon icon="square" class="color-white mr-1 font-size-14" v-else></font-awesome-icon>
            <span class="color-white">Reset to show current images as they're received.</span>
          </a>
        </div>
      </b-nav-form>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-2">
            <b-nav-form class="navy-fields mr-2">
              <b-dropdown class="btn btn-sm btn-primary rounded-pill">
                <template #button-content>
                  <font-awesome-icon icon="project-diagram" size="sm" class="mr-2" /> {{ model }}
                </template>
                <b-dropdown-item @click="model = 'Pneumonia'">Pneumonia</b-dropdown-item>
                <b-dropdown-item @click="model = 'Tuberculosis'">Tuberculosis</b-dropdown-item>
                <b-dropdown-item @click="model = 'General'">General Chest</b-dropdown-item>
              </b-dropdown>
            </b-nav-form>
            <b-nav-item href="https://envisionit.ai/support/" target="_blank" link-classes="btn btn-sm btn-yellow rounded-pill mr-0 px-3">
              <font-awesome-icon icon="info" size="sm" />
            </b-nav-item>
          </b-navbar-nav>
          <!--
          <b-nav-item-dropdown right no-caret>
            <template v-slot:button-content>
              <font-awesome-icon icon="cog"></font-awesome-icon>
            </template>
            <b-dropdown-item href="#">Item 1</b-dropdown-item>
            <b-dropdown-item href="#">Item 2</b-dropdown-item>
            <b-dropdown-item href="#">Item 3</b-dropdown-item>
            <b-dropdown-item href="#">Item 4</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-navbar-nav>
            <b-nav-item href="#">
              <font-awesome-icon icon="bell"></font-awesome-icon>
            </b-nav-item>
          </b-navbar-nav>
          <a href="#" class="profile-icon box-shadow float-right ml-3">
            <span
              class="profile-img"
              :style="'background-image: url(' + require('@/assets/images/avatar-placeholder.jpg') + ')'"
            ></span>
            <span class="initials">JS</span>
          </a>
          -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="px-4 inner-container">
      <h5 class="pl-4 mt-2 title" style="width: 33.3%; padding-right: 15px;">
        Results
        <div class="float-right">
          <a class="mr-1" v-b-tooltip.hover.top="'Focus on today.'" href="#" @click.prevent="sorting = 'date'" v-if="currentImages"><font-awesome-icon icon="calendar-day" size="sm" :class="sorting !== 'date' ? 'color-white' : 'color-yellow'" /></a>
          <a class="mr-1" v-b-tooltip.hover.top="'Sort by AI decision.'" href="#" @click.prevent="sorting = 'priority'"><font-awesome-icon icon="sort-amount-up" size="sm" class="ml-1" :class="sorting !== 'priority' ? 'color-white' : 'color-yellow'" /></a>
          <a v-b-tooltip.hover.top="'Sort by date.'" href="#" @click.prevent="sorting = 'calendar'"><font-awesome-icon icon="calendar-alt" size="sm" :class="sorting !== 'calendar' ? 'color-white' : 'color-yellow'" /></a>
        </div>
      </h5>
      <b-row class="inner-row">
        <b-col cols="4" class="inner-col d-flex flex-column">
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist" v-if="sorting === 'priority'">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('high')">
              <b-button block v-b-toggle.priority-accordion-1 class="py-1">
                <b-badge pill class="indicator normal-font high border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('high') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('high').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-1" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('high')">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('high')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('intermediate')">
              <b-button block v-b-toggle.priority-accordion-2 class="py-1">
                <b-badge pill class="indicator normal-font moderate border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('intermediate') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('intermediate').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-2" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('intermediate')">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('intermediate')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->

            <!-- Accordion 3 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('low')">
              <b-button block v-b-toggle.priority-accordion-3 class="py-1">
                <b-badge pill class="indicator normal-font moderate border-0">
                  <font-awesome-icon class="mr-1" icon="exclamation-triangle"></font-awesome-icon> {{ getModalDescription('low') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('low').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-3" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('low')">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('low')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 3 -->

            <!-- Accordion 4 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('other')">
              <b-button block v-b-toggle.priority-accordion-4 class="py-1">
                <b-badge pill class="indicator normal-font nothing border-0">
                  <font-awesome-icon class="mr-1" icon="heartbeat"></font-awesome-icon> {{ getModalDescription('other') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('other').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-4" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('other')">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('other')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 4 -->

            <!-- Accordion 5 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" v-if="getModalDescription('none')">
              <b-button block v-b-toggle.priority-accordion-5 class="py-1">
                <b-badge pill class="indicator normal-font nothing border-0">
                  <font-awesome-icon class="mr-1" icon="heartbeat"></font-awesome-icon> {{ getModalDescription('none') }}
                  <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('nothing').length }}</b-badge>
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-5" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-if="getModalDescription('none')">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('nothing')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 5 -->

            <!-- Accordion 6 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.priority-accordion-6 class="text-center color-yellow font-weight-900">
                Add Images
              </b-button>
            </b-card-header>
            <b-collapse id="priority-accordion-6" accordion="priority-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <upload-files v-on:refresh-studies="refreshStudies"></upload-files>
            </b-collapse>
            <!-- END | Accordion 6 -->
          </div>
          <!-- END | Accordion -->
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist" v-if="sorting === 'date'">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-1 class="py-2">
                Just now
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('now').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-1" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('now')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-2 class="py-2">
                Ealier Today
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('earlier').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-2" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('earlier')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->

            <!-- Accordion 3 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-3 class="py-2">
                Yesterday
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('yesterday').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-3" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('yesterday')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 3 -->

            <!-- Accordion 4 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-4 class="py-2">
                This week
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('week').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-4" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('week')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 4 -->

            <!-- Accordion 5 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-5 class="py-2">
                Older
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">
                  {{ filteredList('older').length | formatInt }}
                  of {{ total - loadedThisWeek | formatInt }}
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-5" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('older')" :active="selected" v-on:study-selected="selectStudy"></list-view>
              <b-list-group flush v-if="loadedTotal < total">
                <b-list-group-item href="#" @click.prevent="loadMore()" v-if="loadState">
                  <p class="mb-0 text-center text-white">
                    Downloaded {{ loadedTotal | formatInt }} of {{ total | formatInt }} images.
                    <font-awesome-icon icon="download" class="ml-1" />
                    Click to download more.
                  </p>
                </b-list-group-item>
                <b-list-group-item href="#" @click.prevent="loadMore()" v-else>
                  <p class="mb-0 text-center text-white">
                    Downloading next set of images.
                    <font-awesome-icon icon="sync-alt" class="ml-1" />
                  </p>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
            <!-- END | Accordion 5 -->

            <!-- Accordion 6 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.date-accordion-6 class="text-center color-yellow font-weight-900">
                Add Images
              </b-button>
            </b-card-header>
            <b-collapse id="date-accordion-6" accordion="date-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <upload-files v-on:refresh-studies="refreshStudies"></upload-files>
            </b-collapse>
            <!-- END | Accordion 6 -->
          </div>
          <!-- END | Accordion -->
          <!-- Accordion -->
          <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist" v-if="sorting === 'calendar'">
            <!-- Accordion 1 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-1 class="py-2">
                <span v-if="dateRanges && dateRanges.length > 0">{{ dateRanges[0].start | formatDateShort }} - {{ dateRanges[0].end | formatDateShort }}</span>
                <span v-else>Range 1</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('range1').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-1" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('range1')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 1 -->

            <!-- Accordion 2 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-2 class="py-2">
                <span v-if="dateRanges && dateRanges.length > 1">{{ dateRanges[1].start | formatDateShort }} - {{ dateRanges[1].end | formatDateShort }}</span>
                <span v-else>Range 2</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('range2').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-2" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('range2')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 2 -->

            <!-- Accordion 3 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-3 class="py-2">
                <span v-if="dateRanges && dateRanges.length > 2">{{ dateRanges[2].start | formatDateShort }} - {{ dateRanges[2].end | formatDateShort }}</span>
                <span v-else>Range 3</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('range3').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-3" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('range3')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 3 -->

            <!-- Accordion 4 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-4 class="py-2">
                <span v-if="dateRanges && dateRanges.length > 3">{{ dateRanges[3].start | formatDateShort }} - {{ dateRanges[3].end | formatDateShort }}</span>
                <span v-else>Range 4</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">{{ filteredList('range4').length }}</b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-4" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('range4')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 4 -->

            <!-- Accordion 5 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-5 class="py-2">
                <span v-if="dateRanges && dateRanges.length > 4">{{ dateRanges[4].start | formatDateShort }} - {{ dateRanges[4].end | formatDateShort }}</span>
                <span v-else>Range 5</span>
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400">
                  {{ filteredList('range5').length }}
                </b-badge>
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-5" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <list-view :reload="reload" :selected-collapse="selectedCollapse" :list="filteredList('range5')" :active="selected" v-on:study-selected="selectStudy"></list-view>
            </b-collapse>
            <!-- END | Accordion 5 -->

            <!-- Accordion 6 -->
            <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
              <b-button block v-b-toggle.calendar-accordion-6 class="text-center color-yellow font-weight-900">
                Add Images
              </b-button>
            </b-card-header>
            <b-collapse id="calendar-accordion-6" accordion="my-accordion" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100">
              <upload-files v-on:refresh-studies="refreshStudies"></upload-files>
            </b-collapse>
            <!-- END | Accordion 6 -->
          </div>
          <!-- END | Accordion -->
        </b-col>
        <b-col cols="8" class="inner-col d-flex flex-column">
          <result-view v-if="selected" :selected="selectedData" :model="model" :key="selected"></result-view>
          <nothing-view v-else></nothing-view>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { staffSubmissionService } from './../../services'
import { apiRoute, authHeader } from './../../helpers'
import { mapState, mapActions } from 'vuex'
import md5 from 'js-md5'
import moment from 'moment'

import ListView from './components/List'
import NothingView from './components/Nothing'
import ResultView from './components/View'
import UploadFiles from './components/Upload'

export default {
  components: {
    ListView,
    NothingView,
    ResultView,
    UploadFiles
  },
  data () {
    return {
      current: null,
      interval: null,
      period: 'now',
      allLoaded: false,
      selected: null,
      search: null,
      allSubmissions: [], // New array to store all submissions
      now: [],
      earlier: [],
      yesterday: [],
      week: [],
      older: [],
      high: [],
      intermediate: [],
      low: [],
      other: [],
      nothing: [],
      range1: [], // New array for the first date range
      range2: [], // New array for the second date range
      range3: [], // New array for the third date range
      range4: [], // New array for the fourth date range
      range5: [], // New array for the fifth date range
      model: null,
      key: 0,
      sorting: 'date',
      page: 1,
      total: 0,
      dateFrom: new Date(),
      dateTo: null,
      selectingDate: 'from',
      selectedCollapse: null,
      reload: 0,
      currentImages: true,
      dateRangeHash: null,
      searching: false
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      submissions: state => state.staffSubmission
    }),
    ...mapState({
      alert: state => state.alert,
      submissions: state => state.staffSubmission
    }),
    loadState () {
      return this.submissions.status.loaded
    },
    selectedData () {
      if (!this.selected) {
        return null
      } else {
        const findSelected = (arr) => {
          const submissionId = arr.find(id => id === this.selected)
          if (submissionId) {
            const submission = this.allSubmissions.find(item => item.created_at._id === submissionId)
            return submission ? submission.created_at.cell : null
          }
          return null
        }

        let _selected = findSelected(this.high)
        if (_selected) return _selected
        _selected = findSelected(this.intermediate)
        if (_selected) return _selected
        _selected = findSelected(this.low)
        if (_selected) return _selected
        _selected = findSelected(this.other)
        if (_selected) return _selected
        _selected = findSelected(this.nothing)
        if (_selected) return _selected
        _selected = findSelected(this.range1)
        if (_selected) return _selected
        _selected = findSelected(this.range2)
        if (_selected) return _selected
        _selected = findSelected(this.range3)
        if (_selected) return _selected
        _selected = findSelected(this.range4)
        if (_selected) return _selected
        _selected = findSelected(this.range5)
        if (_selected) return _selected

        return null
      }
    },
    loadedTotal () {
      return this.high.length + this.intermediate.length + this.low.length + this.other.length + this.nothing.length
    },
    loadedThisWeek () {
      return this.now.length + this.earlier.length + this.yesterday.length + this.week.length
    },
    dateRanges () {
      try {
        const start = new Date(this.dateFrom)
        const end = new Date(this.dateTo)

        // Normalize dates to midnight to prevent time-related issues
        start.setHours(0, 0, 0, 0)
        end.setHours(0, 0, 0, 0)

        // Calculate total days (inclusive)
        const timeDiff = end.getTime() - start.getTime()
        const totalDays = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1

        const ranges = []

        if (totalDays <= 5) {
          // Create one range per day
          const current = new Date(start)
          for (let i = 0; i < totalDays; i++) {
            const currentEnd = new Date(current)
            ranges.push({ start: new Date(current), end: currentEnd })
            current.setDate(current.getDate() + 1)
          }
        } else {
          // Split into 5 ranges with full-day increments
          const baseDays = Math.floor(totalDays / 5)
          const remainderDays = totalDays - (baseDays * 4)

          let currentStart = new Date(start)

          // Create first 4 ranges
          for (let i = 0; i < 4; i++) {
            const currentEnd = new Date(currentStart)
            currentEnd.setDate(currentEnd.getDate() + baseDays - 1)
            ranges.push({ start: new Date(currentStart), end: new Date(currentEnd) })
            currentStart = new Date(currentEnd)
            currentStart.setDate(currentStart.getDate() + 1)
          }

          // Create final range
          const finalEnd = new Date(currentStart)
          finalEnd.setDate(finalEnd.getDate() + remainderDays - 1)
          ranges.push({ start: new Date(currentStart), end: finalEnd })
        }

        // Reverse to put latest dates first and format dates
        console.log('Date Ranges:', ranges)
        return ranges.reverse().map(range => ({
          start: range.start,
          end: new Date(range.end.setHours(23, 59, 59))
        }))
      } catch (err) {
        console.log(err)
        return []
      }
    },
    rangedData () {
      if (this.dateRanges && this.dateRanges.length > 0) {
        const rangedData = []
        const allRows = [...this.now, ...this.earlier, ...this.yesterday, ...this.week, ...this.older]

        this.dateRanges.forEach(range => {
          rangedData.push(allRows.filter(d => d.created_at.date >= range.start && d.created_at.date <= range.end))
        })

        return rangedData
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('staffSubmission', {
      getCovid: 'getCovid',
      getPneumonia: 'getPneumonia',
      getTuberculosis: 'getTuberculosis',
      getGeneral: 'getGeneral',
      getByDateRange: 'getByDateRange',
      searchSubmissions: 'search',
      clearSubmissions: 'clear'
    }),
    distributeIntoDateRanges () {
      this.range1 = []
      this.range2 = []
      this.range3 = []
      this.range4 = []
      this.range5 = []
      this.allSubmissions.forEach(_record => {
        let addedToRange = false
        for (let i = 0; i < this.dateRanges.length; i++) {
          const range = this.dateRanges[i]
          if (_record.created_at.date >= range.start && _record.created_at.date <= range.end) {
            this[`range${i + 1}`].push(_record.created_at._id)
            addedToRange = true
            break // Stop after finding the correct range
          }
        }
        if (!addedToRange) {
          console.log('error with range')
        }
      })
    },
    refreshStudies: function () {
      /*
      this.period = 'now'
      this.getAllSubmissions(this.period)
      */
    },
    filteredList: function (period) {
      if (!this[period]) {
        return []
      } else {
        const submissions = this[period]
          .map(id => this.allSubmissions.find(sub => sub.created_at._id === id))
          .filter(sub => sub !== undefined) // Ensure no undefined entries

        if (!this.search) {
          return submissions
        } else {
          return submissions.filter(row =>
            (row.patient.demographics.name || row.patient.identifier).toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
            row.patient.identifier.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          )
        }
      }
    },
    displayResult: async function (data) {
      this.current = data

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(apiRoute() + '/api/v1/staff/covid/heatmaps/' + this.current.submission._id, requestOptions)
      const results = await _response.json()
      this.current.heatmaps = results
    },
    getAllSubmissions: function (period) {
      if (this.model === 'Pneumonia') {
        this.getPneumonia({ period: period, page: period === 'older' ? this.page : 1 })
      } else if (this.model === 'COVID') {
        this.getCovid(period)
      } else if (this.model === 'Tuberculosis') {
        this.getTuberculosis({ period: period, page: period === 'older' ? this.page : 1 })
      } else {
        this.getGeneral({ period: period, page: period === 'older' ? this.page : 1 })
      }
    },
    loadMore: function () {
      clearInterval(this.interval)
      this.interval = null
      this.allLoaded = false
      this.period = 'older'
      this.page += 1
      this.getAllSubmissions(this.period)
    },
    getModalDescription: function (name) {
      if (this.model !== 'General') {
        if (name === 'high') {
          return 'High Probability of ' + this.model
        } else if (name === 'intermediate') {
          if (this.model === 'Tuberculosis') {
            return null
          } else {
            return 'Intermediate Probability of ' + this.model
          }
        } else if (name === 'low') {
          return 'Low Probability of ' + this.model
        } else if (name === 'other') {
          return 'Other Pathologies'
        } else {
          return 'No Pathologies Found'
        }
      } else {
        if (name === 'high') {
          return 'Abnormality score Over 0.75'
        } else if (name === 'intermediate') {
          return 'Abnormality score Between 0.45 and 0.75'
        } else if (name === 'low') {
          return 'Abnormality score Below 0.45'
        } else if (name === 'other') {
          return null
        } else {
          return 'No Pathologies Found'
        }
      }
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getConclusionClass: function (name) {
      if (name.indexOf('high') > -1) {
        return 'text-danger'
      } else if (name.indexOf('intermediate') > -1) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    },
    getCurrentClass: function (_id) {
      if (this.current && this.current.submission) {
        if (this.current.submission._id === _id) {
          console.log('Selected')
          return 'selected-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    originalImage: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].path
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].response.bounding.path
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'cardiomegaly')
        if (_pathology) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    selectStudy: async function (id) {
      this.selected = id
    },
    onDateFromSelected (date) {
      this.dateTo = null
      this.selectingDate = 'to'
    },
    onDateToSelected (date) {
      this.selectingDate = 'from'
      this.currentImages = false
      this.sorting = 'calendar'
      this.$refs['calendar-dropdown'].hide()
      this.distributeIntoDateRanges()

      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
      this.dateRangeHash = md5(Date.now().toString())

      this.getByDateRange({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        period: this.dateRangeHash
      })
    },
    dateInfoFn (date) {
      if (this.selectingDate === 'to' && date < this.dateFrom) {
        return { disabled: true }
      }
      return null
    },
    toggleAccordion () {
      if (this.sorting === 'date') {
        if (this.now.length > 0 && this.selectedCollapse !== 'date-accordion-1') {
          this.$root.$emit('bv::toggle::collapse', 'date-accordion-1')
        } else if (this.earlier.length > 0 && this.selectedCollapse !== 'date-accordion-2') {
          this.$root.$emit('bv::toggle::collapse', 'date-accordion-2')
        } else if (this.yesterday.length > 0 && this.selectedCollapse !== 'date-accordion-3') {
          this.$root.$emit('bv::toggle::collapse', 'date-accordion-3')
        } else if (this.week.length > 0 && this.selectedCollapse !== 'date-accordion-4') {
          this.$root.$emit('bv::toggle::collapse', 'date-accordion-4')
        } else if (this.older.length > 0 && this.selectedCollapse !== 'date-accordion-5') {
          this.$root.$emit('bv::toggle::collapse', 'date-accordion-5')
        }
      } else if (this.sorting === 'priority') {
        if (this.high.length > 0 && this.selectedCollapse !== 'priority-accordion-1') {
          this.$root.$emit('bv::toggle::collapse', 'priority-accordion-1')
        } else if (this.intermediate.length > 0 && this.selectedCollapse !== 'priority-accordion-2') {
          this.$root.$emit('bv::toggle::collapse', 'priority-accordion-2')
        } else if (this.low.length > 0 && this.selectedCollapse !== 'priority-accordion-3') {
          this.$root.$emit('bv::toggle::collapse', 'priority-accordion-3')
        } else if (this.other.length > 0 && this.selectedCollapse !== 'priority-accordion-4') {
          this.$root.$emit('bv::toggle::collapse', 'priority-accordion-4')
        } else if (this.nothing.length > 0 && this.selectedCollapse !== 'priority-accordion-5') {
          this.$root.$emit('bv::toggle::collapse', 'priority-accordion-5')
        }
      } else if (this.sorting === 'calendar') {
        if (this.range1.length > 0 && this.selectedCollapse !== 'calendar-accordion-1') {
          this.$root.$emit('bv::toggle::collapse', 'calendar-accordion-1')
        } else if (this.range2.length > 0 && this.selectedCollapse !== 'calendar-accordion-2') {
          this.$root.$emit('bv::toggle::collapse', 'calendar-accordion-2')
        } else if (this.range3.length > 0 && this.selectedCollapse !== 'calendar-accordion-3') {
          this.$root.$emit('bv::toggle::collapse', 'calendar-accordion-3')
        } else if (this.range4.length > 0 && this.selectedCollapse !== 'calendar-accordion-4') {
          this.$root.$emit('bv::toggle::collapse', 'calendar-accordion-4')
        } else if (this.range5.length > 0 && this.selectedCollapse !== 'calendar-accordion-5') {
          this.$root.$emit('bv::toggle::collapse', 'calendar-accordion-5')
        }
      }
    },
    onCollapse (id, state) {
      if (state) {
        this.selectedCollapse = id
      }
    },
    resetLoadedImages () {
      clearInterval(this.interval)
      this.interval = null
      this.current = null
      this.selected = null
      this.period = 'now'
      this.allLoaded = false
      this.search = null
      this.allSubmissions = []
      this.now = []
      this.earlier = []
      this.yesterday = []
      this.week = []
      this.older = []
      this.high = []
      this.intermediate = []
      this.low = []
      this.other = []
      this.nothing = []
      this.page = 1
      this.total = 0
      this.dateTo = null
      this.dateFrom = new Date()
      this.searching = false

      this.$nextTick(() => {
        this.getAllSubmissions(this.period)
        this.reload++
      })
    }
  },
  mounted () {
    this.dateTo = new Date()
    this.dateFrom = new Date(new Date().setDate(1)) // Start of current month

    const _model = localStorage.getItem('ai_model')
    if (_model) {
      this.model = _model
    } else {
      this.model = 'Tuberculosis'
    }

    this.$root.$on('bv::collapse::state', this.onCollapse)
  },
  destroyed () {
    clearInterval(this.interval)
    this.interval = null

    this.$root.$off('bv::collapse::state', this.onCollapse)
  },
  watch: {
    model: function () {
      localStorage.setItem('ai_model', this.model)
      this.$root.$emit('bv::toggle::collapse', this.selectedCollapse)
      this.selectedCollapse = null
      this.resetLoadedImages()
    },
    loadState (loaded) {
      if (loaded && this.alert.message == null && this.alert.type == null) {
        const getProbability = function (name) {
          if (name.indexOf('high') > -1) {
            return 'high'
          } else if (name.indexOf('intermediate') > -1) {
            return 'intermediate'
          } else if (name.indexOf('low') > -1) {
            return 'low'
          } else if (name.indexOf('other') > -1) {
            return 'other'
          } else {
            return 'nothing'
          }
        }

        const _qualifier = this.currentImages ? (this.searching ? 'search' : this.period) : this.dateRangeHash
        const rowData = this.submissions.info[_qualifier]
        console.log('Using qualifier', _qualifier, rowData.length)
        if (rowData) {
          if (this.currentImages) {
            this.dateFrom = rowData.reduce((earliest, current) => {
              const currentDate = new Date(current.submission.created_at)
              return (earliest === undefined || currentDate < earliest) ? currentDate : earliest
            }, new Date(this.dateFrom))

            this.dateTo = rowData.reduce((latest, current) => {
              const currentDate = new Date(current.submission.created_at)
              const _return = (latest === undefined || currentDate > latest) ? currentDate : latest
              return _return
            }, this.dateTo === '' || !this.dateTo ? undefined : new Date(this.dateTo))
          }

          rowData.forEach(row => {
            if (!row.submission.patient) {
              row.submission.patient = {
                _id: row.submission._id,
                clinical: {},
                created_at: row.submission.created_at,
                demographics: {
                  ageGroup: 'Unknown',
                  country: 'Unknown',
                  gender: 'Unknown',
                  province: 'Unknown'
                },
                identifier: row.submission.study_id
              }
            }

            const _record = {
              created_at: { date: moment(row.submission.created_at).toDate(), raw_date: moment(row.submission.created_at), _id: row.submission._id, cell: row },
              conclusion: row.analysis.conclusion ? row.analysis.conclusion.toLowerCase() : 'Unknown',
              type: undefined,
              score: row.analysis.score,
              patient: row.submission.patient,
              feedback: false,
              probability: getProbability(row.analysis.conclusion ? row.analysis.conclusion.toLowerCase() : 'Unknown')
            }

            if (row.submission.attachments && row.submission.attachments.length > 0 && row.submission.attachments[0].feedback) {
              _record.feedback = true
            }

            if (!this.allSubmissions.find(r => r.created_at._id === _record.created_at._id)) {
              this.allSubmissions.push(_record)

              // Populate the ID arrays
              this[this.period].push(_record.created_at._id)
              this[_record.probability].push(_record.created_at._id)
            }
          })

          if (this.period === 'now') {
            staffSubmissionService.getCount()
              .then(
                response => {
                  if (response) {
                    this.total = parseInt(response.count)
                  }
                  this.period = 'earlier'
                  this.getAllSubmissions(this.period)
                },
                error => {
                  console.log(error)
                  this.period = 'earlier'
                  this.getAllSubmissions(this.period)
                }
              )
          } else if (this.period === 'earlier') {
            if (!this.allLoaded) {
              this.period = 'yesterday'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'yesterday') {
            if (!this.allLoaded) {
              this.period = 'week'
              this.getAllSubmissions(this.period)
            }
          } else if (this.period === 'week') {
            if (!this.allLoaded) {
              this.period = 'older'
              this.getAllSubmissions(this.period)
            }
          } else {
            this.allLoaded = true
            this.distributeIntoDateRanges()

            if (this.currentImages || this.searching) {
              this.searching = false
              const self = this
              if (!this.interval) {
                this.interval = setInterval(() => {
                  self.period = 'now'
                  self.getAllSubmissions(self.period)
                }, 30000)
              }
            }
          }

          this.reload++
        } else {
          // Handling updates for existing submissions or when this.submissions.info is not an array
          if (this.submissions.info && this.submissions.info._id) {
            const _id = this.submissions.info._id
            const _record = this.submissions.info[_id]

            const _probabilities = ['high', 'intermediate', 'low', 'other', 'nothing']
            const _periods = ['now', 'earlier', 'yesterday', 'week', 'older']
            const _ranges = ['range1', 'range2', 'range3', 'range4', 'range5']

            // Helper function to update record
            const updateRecord = (record) => {
              if (!record.submission.patient) {
                record.submission.patient = {
                  _id: record.submission._id,
                  clinical: {},
                  created_at: record.submission.created_at,
                  demographics: {
                    ageGroup: 'Unknown',
                    country: 'Unknown',
                    gender: 'Unknown',
                    province: 'Unknown'
                  },
                  identifier: record.submission.study_id
                }
              }

              return {
                created_at: { date: moment(record.submission.created_at).toDate(), raw_date: moment(record.submission.created_at), _id: record.submission._id, cell: record },
                conclusion: record.analysis.conclusion ? record.analysis.conclusion.toLowerCase() : 'Unknown',
                type: undefined,
                score: record.analysis.score,
                patient: record.submission.patient,
                feedback: !!(record.submission.attachments && record.submission.attachments.length > 0 && record.submission.attachments[0].feedback),
                probability: getProbability(record.analysis.conclusion ? record.analysis.conclusion.toLowerCase() : 'Unknown')
              }
            }

            // Update allSubmissions
            const allSubmissionsIndex = this.allSubmissions.findIndex(item => item.created_at._id === _id)
            if (allSubmissionsIndex > -1) {
              this.allSubmissions[allSubmissionsIndex] = updateRecord(_record)
            }

            // Update probability arrays
            _probabilities.forEach(_probability => {
              const index = this[_probability].indexOf(_id)
              if (index > -1) {
                if (updateRecord(_record).probability === _probability) {
                  // If probability hasn't changed, do nothing as allSubmissions is already updated
                } else {
                  // If probability has changed, remove from current array
                  this[_probability].splice(index, 1)
                }
              } else if (updateRecord(_record).probability === _probability) {
                // If not in the array, but should be, add it
                this[_probability].push(_id)
              }
            })

            // Update period arrays
            _periods.forEach(_period => {
              const index = this[_period].indexOf(_id)
              if (index > -1) {
                // If present, do nothing as allSubmissions is already updated
              } else {
                // Check if it should be in this period based on date
                // This requires more precise date comparison logic, omitted for brevity
              }
            })

            // Update range arrays
            if (this.sorting === 'calendar') {
              _ranges.forEach(range => {
                const index = this[range].indexOf(_id)
                if (index > -1) {
                  this[range].splice(index, 1) // Remove from current range
                }
              })

              // Add to the correct range
              let addedToRange = false
              for (let i = 0; i < this.dateRanges.length; i++) {
                const range = this.dateRanges[i]
                if (updateRecord(_record).created_at.date >= range.start && updateRecord(_record).created_at.date <= range.end) {
                  this[`range${i + 1}`].push(_id)
                  addedToRange = true
                  break
                }
              }
              if (!addedToRange) {
                console.log('error with range')
              }
            }

            if (this.sorting === 'priority' || this.sorting === 'date') {
              this.selected = null
              this.$nextTick(() => {
                this.selected = _id
              })
            }
          }
        }
      }
    },
    allLoaded () {
      if (this.allLoaded) {
        if (this.period === 'older' && this.page > 1) {
          // Do nothing, we already focused the right accordion
        } else {
          this.toggleAccordion()
        }
      }
    },
    sorting () {
      this.$root.$emit('bv::toggle::collapse', this.selectedCollapse)
      this.$nextTick(() => {
        this.toggleAccordion()
      })
    },
    currentImages () {
      if (this.currentImages) {
        this.sorting = 'date'
        this.currentImages = true
        this.resetLoadedImages()
      }
    },
    search () {
      if (this.search && this.search.length >= 3) {
        if (!this.searching && this.currentImages) {
          if (this.interval) {
            clearInterval(this.interval)
            this.interval = null
          }

          this.searching = true
          this.searchSubmissions({
            period: 'search',
            term: this.search
          })
        }
      }
    }
  }
}
</script>

<style>
  .calendar-dropdown ul {
    background-color: #081624
  }

  .calendar-container {
    background-color: #081624;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 600px
  }

  .b-calendar .b-calendar-nav .btn {
    padding: 0.25rem !important
  }

  .b-calendar-grid {
    width: inherit !important
  }

  .b-calendar footer {
    display: none
  }

  .b-calendar .form-control, .b-calendar .btn {
    color: #fff !important
  }
</style>
